<template>
    <!-- Logout Modal-->
    <div class="modal fade" id="deleteMenuModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Окно подтверждения</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="h3 form-group">
                        <label><strong>Вы уверены удалить этот раздел ?</strong></label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="title=''" type="button" class="btn btn-secondary" data-dismiss="modal">Отменить
                    </button>
                    <button @click="deleteMenuTitle" type="button" data-dismiss="modal" class="btn btn-primary">Сохранить изменения
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: Object,
        url: String,
    },
    name: "deleteMenuModal",
    data: () => {
        return ({
            title: "",
            visibly: ""
        })
    },
    methods: {
        deleteMenuTitle() {
            axios.post(this.url+'/admin/api/deleteMenuTitle', {
                id: this.value.id,
            }).then((response) => {
                this.$emit('delete');
                this.title = "";
                this.$parent.flashDanger(response.data.data);
                // window.location.href = this.currentUrl;
            }).catch(function (error) {
                console.log(error);
            });
        },
    },
}
</script>

<style scoped>

</style>
