<template>
    <li class="l_group_item">
        <div class="item_block">
            <div class="px-2 btn text-muted"  @click="toggleChildren">
                <i
                    v-if="menuChildrens.length>0"
                    :class="'fas fa-angle-'+getAngleClass">
                </i>
            </div>

            <div class="item_bc">
                <a :href="url+'/'+'admin'+'/'+'menu'+'/'+'content'+'/'+this.value.id">
                    {{ currentLangOfMenu.title }}
                    <span class="badge badge-primary badge-pill">{{ menuChildrens.length }} </span>
                </a>
                <div class="iblockpost add">
                    <a :href="url+'/'+'admin'+'/'+'menu'+'/'+'posts'+'/'+this.value.id">
                        Посты
                    </a>
                </div>
                <div @click="onSelectMenuItem" class="iblock add" data-toggle="modal"
                     data-target="#addMenuChildrenModal">
                    <i class="fas fa-plus"></i>
                </div>
                <div @click="onSelectMenuItem" class="iblock edit" data-toggle="modal" data-target="#editMenuModal">
                    <i class="fas fa-pencil-alt"></i>
                </div>
                <div @click="onSelectMenuItem" class="iblock del" data-toggle="modal" data-target="#deleteMenuModal">
                    <i class="fas fa-trash-alt"></i>
                </div>
            </div>
        </div>


        <ul class="pl-3"  v-if="showChildren"> <!--l_group-->
            <sub-menu
                v-if="showChildren"
                :url="url"
                @select="$emit('select', $event)"
                v-for="(item,index) in menuChildrens"
                v-model="menuChildrens[index]"
                :submenu="submenu"
                ref="recursiveSubMenu"
                :lang="lang"
                :key="index"
            />
        </ul>
    </li>

</template>

<script>
import SubMenu from "./submenu.vue"

export default {
    name: "sub-menu",
    props: {
        value: Object,
        submenu: Array,
        lang: String,
        url: String
    },
    data() {
        return {
            menuChildrens: [],
            currentLangOfMenu: {},
            selectedMenuItem: {},
            showChildren: false,
            position: Object,
        }
    },
    computed:{
        getAngleClass(){
            return this.showChildren ? "down" : "right";
        }
    },
    methods: {
        onSelectMenuItem() {
            /*it equals to the value in addressMenuModal, editMenuModal*/
            this.selectedMenuItem = {
                lang: this.lang,
                id: this.value.id,
                title: this.currentLangOfMenu.title,

                address: this.currentLangOfMenu.address,
                phone: this.currentLangOfMenu.phone,
                fax: this.currentLangOfMenu.fax,
                website: this.currentLangOfMenu.website,
                email: this.currentLangOfMenu.email,

                position: this.menuChildrensPosition[0].position,
            }
            this.$emit("select", this.selectedMenuItem)
        },
        reload() {
            this.menuChildrens = this.submenu.filter(x => x.parent_id == this.value.id);
            this.menuChildrensPosition = this.submenu.filter(x => x.id == this.value.id);
            let i = 0;
            for (let index = 0; index < this.value.translates.length; index++) {
                if (this.value.translates[index].locale === this.lang) {
                    i = index;
                    break;
                }
            }
            this.currentLangOfMenu = this.value.translates[i];

        },
        toggleChildren: function() {
            this.showChildren = !this.showChildren;
        },

    },
    components: {
        SubMenu
    },
    mounted() {
        this.reload();
        // console.log(this.currentLangOfMenu.address)
    },
    watch: {
        submenu: function () {
            this.reload();
        }
    }
}
</script>

<style scoped>

</style>
