<template>
    <!-- Logout Modal-->
    <div class="modal fade" id="editMenuModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Изменение раздела</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Название раздела на выбраннем языке </label>
                            <input v-model="value.title"  type="text" id="exampleInputEmail1" class="form-control" placeholder="Введите название раздела">
                            <input v-model="value.id" hidden>
                            <input v-model="value.lang" hidden>
                        </div>
                        <div class="form-group">
                            <label for="address">Адрес</label>
                            <input v-model="value.address"  type="text" id="address" class="form-control" placeholder="Введите адрес">
                        </div>
                        <div class="form-group">
                            <label for="phone">Телефон</label>
                            <input v-model="value.phone" type="text" id="phone" class="form-control" placeholder="Введите телефон">
                        </div>
                        <div class="form-group">
                            <label for="fax">Факс</label>
                            <input v-model="value.fax" type="text" id="fax" class="form-control" placeholder="Введите факс">
                        </div>
                        <div class="form-group">
                            <label for="website">Cайт</label>
                            <input v-model="value.website" type="text" id="website" class="form-control" placeholder="Введите вебсайт">
                        </div>
                        <div class="form-group">
                            <label for="email">Эл.почта</label>
                            <input v-model="value.email" type="text" id="email" class="form-control" placeholder="Введите эл.почту">
                        </div>
                        <div class="form-group">
                            <label for="position">Позиция</label>
                            <input v-model="selectedMenuItem.position"  type="text" id="position" class="form-control" placeholder="Введите позицию раздела">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="title=''" type="button" class="btn btn-secondary" data-dismiss="modal">Отмена</button>
                    <button @click="editMenuTitle" type="button" data-dismiss="modal" class="btn btn-primary">Сохранить изменения</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: Object,
        url: String,
        selectedMenuItem: Object,
    },
    name: "editMenuModal",
    data:()=>{
        return({
            title:"",
            visibly: "",
        })
    },
    methods:{
        editMenuTitle(){
            axios.post(this.url+'/admin/api/editMenuTitle',{
                title: this.value.title,
                id: this.value.id,
                lang: this.value.lang,
                address: this.value.address,
                phone: this.value.phone,
                fax: this.value.fax,
                website: this.value.website,
                email: this.value.email,
                position : this.selectedMenuItem.position,
            }).then((response)=> {
                this.$emit('edit');
                this.title="";
                // this.$parent.flashDanger(response.data.data);
            }).catch( function (error){
                console.log(error);
            });
        }
    },
    mounted() {
        // console.log(this.selectedMenuItem.position)
    },

}
</script>

<style scoped>

</style>
