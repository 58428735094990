const files = document.querySelectorAll('.file-id');
const fileIdModal = document.querySelector("#file-id-modal");
const inputsInModal = document.querySelector("#file-comments-modal");
const languages = document.querySelector("#languages");

if (files) {
for (const file of files) {
    file.addEventListener('click', function () {
        fileIdModal.value = this.getAttribute('data-id');
        const inputs = document.querySelectorAll("#comments .inputs"+this.getAttribute('data-id'));
        if (inputs.length){
            inputsInModal.innerHTML =  Array.from(document.querySelectorAll("#comments .inputs"+this.getAttribute('data-id')))
                .map(x => getHtml(x))
                .join('');
        }
        else {
            inputsInModal.innerHTML =  Object.entries((JSON.parse(languages.value))).map(x => getEmptyHtml(x)).join('');
        }
    })
}
const getHtml = comment => `
     <div class="form-group">
        <label>${comment.dataset.lang}</label>
        <input type="text" class="form-control" name="${comment.name}" value="${comment.value}"  aria-describedby="">
     </div>
`
const getEmptyHtml = comment =>
    `
     <div class="form-group">
        <label>${comment[1]}</label>
        <input type="text" class="form-control" name="comment_${comment[0]}" value=""  aria-describedby="">
     </div>
`
}

// for (let i = 0; i < files.length; i++) {
//     files[i].addEventListener('click', function () {
//         fileIdModal.value = this.getAttribute('data-id');
//         const inputs = []
//         const comments = document.querySelectorAll("#comments .inputs"+this.getAttribute('data-id'));
//         for (let s = 0; s < comments.length; s++) {
//           inputs.push(getHtml(comments[s]))
//         };
//         inputsInModal.innerHTML = inputs.join('');
//                // alert(inputs)
//         // let inputs = document.querySelectorAll("#file-comments-modal");
//         // let inputs = document.querySelectorAll("#+""+"inputs");
//         // visible_id.value = this.getAttribute('data-id');
//     })
// }

