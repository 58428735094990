<template>
    <div>
        <div v-if="copySuccess" class="alert alert-success alert-dismissible fade show">
            <span>Ссылка картинки успешно скопирована</span>
            <button @click="copySuccess = false" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-2 col-6 osh-modal-height">
                <img v-if="imageStatus == true" :src="baseUrl+'/storage/'+thumbnailImage" alt="..."
                     class="img-thumbnail cursor-pointer object-fit-contain" data-toggle="modal"
                     data-target="#imageModal" @click="showModal">
                <img v-else="imageStatus == false" :src="baseUrl+'/'+defaultImage" alt="..."
                     class="img-thumbnail cursor-pointer object-fit-contain"
                     data-toggle="modal"
                     data-target="#imageModal" @click="showModal">

                <div v-if="imageStatus">
                    <input name="thumbnail_image" hidden v-model="thumbnailImage">
                    <input name="image" hidden v-model="image">
                </div>
            </div>
        </div>

        <div v-if="isShowImageModal" class="modal fade" id="imageModal" tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Все файлы</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input class="form-control mb-3" v-model="keywords" id="searchByDescription" type="text" placeholder="Поиск по описанию фото">
<!--                        <ul v-if="results.length > 0">-->
<!--                            <li v-for="result in results" :key="result.id" v-text="result.image"></li>-->
<!--                        </ul>-->

                        <div class="row">
                            <div v-for="post in imagesFromDB.data" :key="post.id" class="col-sm-4 col-lg-2 col-6 pb-3">
                                <div class="modal-inner-img osh-modal-height">
                                    <img
                                        v-if="isImg(post.thumbnail_image)" :src="baseUrl+'/storage/'+post.thumbnail_image" alt="..."
                                        class="img-thumbnail cursor-pointer object-fit-contain" data-dismiss="modal">
                                    <img
                                        v-else :src="baseUrl+'/public/images/docType.png'" alt="..."
                                        class="img-thumbnail cursor-pointer object-fit-contain" data-dismiss="modal">

                                    <div class="main-modal-layer">
                                        <div class="select-modal-layer">
                                            <i
                                                @click="selectImage(post.image,post.thumbnail_image);"
                                                class="fas fa-check h2 text-success cursor-pointer" data-dismiss="modal"
                                            ></i>
                                            <input class="hidden" type="text" :id="'imageId'+post.id"
                                                   :value="baseUrl+'/storage/'+post.thumbnail_image">
                                        </div>
                                        <div class="copy-modal-layer">
                                            <i
                                                class="fas fa-link h2 cursor-pointer text-primary"
                                                @click="copyToClickBoard(post.id);" data-dismiss="modal"
                                            ></i>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="d-flex justify-center pb-3">
                        <pagination :data="imagesFromDB" @pagination-change-page="getImages"></pagination>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="closeImagesModal" class="btn btn-secondary" data-dismiss="modal">
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import _ from "lodash";
export default {
    name: "imagesCreateModal",
    props: {
        baseUrl: String,
        currentUrl: String,
    },
    data: () => ({
        isShowImageModal: false,
        // Our data object that holds the Laravel paginator data
        imagesFromDB: {},
        imageStatus: false,
        thumbnailImage: '',
        defaultImage: 'images/icon.png',
        image: '',
        copySuccess: false,
        keywords: null,
        // results: []
    }),
    mounted() {
        this.getImages();
    },

    watch: {
        keywords(after, before) {
            this.getImages();
        }
    },

    methods: {
        getImages:_.debounce(function(page = 1) {
            axios.get('/admin/api/search-by-description', {
                params: {
                    keywords: this.keywords,
                    page: page,
                }
            })
                .then(response => {
                    // this.results = response.data,
                    this.imagesFromDB = response.data;
                })
                .catch(error => {});
        }, 700),
        showModal(){
            this.isShowImageModal = true;
            // this.getImages();
        },
        selectImage(image, thumbnail_image) {
            this.isShowImageModal = false;
            this.thumbnailImage = thumbnail_image;
            this.image = image;
            this.imageStatus = true;
        },
        closeImagesModal() {
            this.isShowImageModal = false;
        },
        copyToClickBoard(text) {
            // find the element we will select
            let copyInput = document.querySelector('#imageId' + text)
            // display the input
            copyInput.style.display = 'block'
            // select that input
            copyInput.select()
            // copy it!
            document.execCommand("copy")
            // hide the input
            copyInput.style.display = 'none';
            this.copySuccess = true;
        },
        isImg(str){
            const typeImg = /.jpg|.jpeg|.png|.JPG|.JPEG|.PNG|.webP|.gif/;
            if (typeImg.exec(str)){
                return true;
            }
        }
    }
}
</script>

<style scoped>

</style>
