<template>
    <div>
        <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            v-on:vdropzone-success="uploadSuccess"
            v-on:vdropzone-error="uploadError"
            v-on:vdropzone-removed-file="fileRemoved"
        >
            <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">Перетащите, чтобы загрузить файл, документ!</h3>
<!--                <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>-->
                <div class="subtitle">.. или щелкните, чтобы выбрать файл на своем компьютере</div>
<!--                <div class="subtitle">...or click to select a file from your computer</div>-->
            </div>
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
    name: "FileUploaderComponent",
    props: {
        baseUrl: String,
        currentUrl: String,
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                url: "/admin/api/uploadFile",
                headers: {
                    "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
                },
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 16, // MB
                params:{
                    // userId : this.userId,
                },
                // addRemoveLinks: true,
                thumbnailWidth: 100,
                thumbnailHeight: 100,
                maxFiles: 200
            },
            fileName: ''
        };
    },
    methods: {
        uploadSuccess(file, response) {
            console.log('File Successfully Uploaded with file name: ' + response.file);
            this.fileName = response.file;
        },
        uploadError(file, message) {
            console.log('An Error Occurred');
        },
        fileRemoved() {

        }
    }
}
</script>

<style scoped>
.vue-dropzone{
    /*border: 1px solid #dee2e6;*/
    border-radius: 0.25rem;
    padding: 0px 0px;
}
</style>
