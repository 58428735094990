<template>
    <div>
        <button type="button" class="btn btn-danger btn-sm"  data-toggle="modal"
           :data-target="'#deletePostModal'+postId+lang" @click="isShowDeleteModal=true">
            Удалить
        </button>
        <!--        <update-post-modal  selectedPost="selectedPost" />-->
        <div v-if="isShowDeleteModal" class="modal fade" :id="'deletePostModal'+postId+lang"  tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Окно подтверждения</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                            <div class="h3 form-group">
                                <label><strong> <!--Are you sure you want to delete this post ?--> Вы уверены удалить этот пост ?</strong></label>
                            </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="closeDeletePostModal" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" @click="deletePostSubmit();closeDeletePostModal();" data-dismiss="modal" class="btn btn-primary">Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

// import updatePostModal from "./updatePostModal.vue";
export default {
    name: "deletePostButton",
    props: {
        postId: String,
        lang: String,
        baseUrl: String,
        currentUrl: String,
    },
    data:()=>({
        isShowDeleteModal:false,
        // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      }),
    methods: {
        deletePostSubmit(){
            axios.delete(this.baseUrl+"/admin/api/deletePost",{
                params: {
                    id: this.postId,
                }
            }).then((response)=> {
                window.location.href = this.currentUrl;
            }).catch( function (error){
                console.log(error);
            });
        },
        closeDeletePostModal(){
            this.isShowDeleteModal = false
        }
    }
}
</script>

<style scoped>

</style>

<!--<input type="hidden" name="_token" :value="csrf">-->

