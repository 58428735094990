window._ = require('lodash');

// window.Popper = require('popper.js').default;

try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
//
// import Vue from 'vue';
// import MenuPage from '../components/menu';
// new Vue({
//     el: '#app',
//     components:{
//         MenuPage
//     }
// });

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// let token = document.head.querySelector('meta[name="csrf-token"]');
//
// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }

// // Laravel's JavaScript scaffolding...
// window.axios.defaults.headers.common = {
//     'X-Requested-With': 'XMLHttpRequest',
// };
// window.axios.defaults.headers.common = {
//     'X-Requested-With': 'XMLHttpRequest',
//     'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
// };

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');


// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


window.Vue = require("vue");




// import VueAxios from 'vue-axios';

// Adding the X-CSRF-Token to all axios request
// axios.interceptors.request.use(function(config){
//     config.headers['X-CSRF-TOKEN'] = window.Laravel.csrfToken
//     return config
// })

// Vue.prototype.$http = axios;



//Плагины
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
Vue.component('pagination', require('laravel-vue-pagination'));

import DeletePostButton from '../components/deletePostButton';
import MenuPage from '../components/menu';
import ImagesCreateModal from '../components/imagesCreateModal';
import ImagesEditModal from '../components/imagesEditModal';
import GalleryCreateModal from '../components/galleryCreateModal';
import GalleryEditModal from '../components/galleryEditModal';
import SelectSection from '../components/selectSection';


import FileUploaderComponent from '../components/FileUploaderComponent.vue';


window.onload = function() {
   new Vue({
        el: '#vue',
        components: {
            MenuPage,
            DeletePostButton,
            ImagesCreateModal,
            ImagesEditModal,
            GalleryCreateModal,
            GalleryEditModal,
            SelectSection,
            FileUploaderComponent,
        }
    });
    new Vue({
        el: '#vue2',
        components: {
            FileUploaderComponent,
        }
    });
}


// axios.defaults.headers.common = {
//     'X-CSRF-TOKEN': Laravel.csrfToken,
//     'X-Requested-With': 'XMLHttpRequest',
//     'Authorization': 'Bearer ' + Laravel.apiToken,
// };
// window.Vue.prototype.$http = axios;




