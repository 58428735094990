<template>
    <div class="d-flex justify-between rounded-lg shadow-md p-2 mb-3" :class="variant">
        <div class="flex items-center">
           {{ message }}
        </div>
        <button @click="close" class="opacity-75 cursor-pointer py-2 px-3 hover:opacity-100">x</button>
    </div>
</template>

<script>
export default {
    name: "flash",
    props:{
        flashType:String,
    },
    methods: {
        close() {
            this.$parent.flashDanger();
        }
    },
    computed: {
        variant() {
            return this.flashType === 'danger' ? "bg-red-200 text-red-900" : "bg-green-200 text-green-900" ;
        },
        message() {
            return this.flashType === 'danger' ? "Раздел не удален. В данном раздела есть подразделы или посты." : "Раздел успешно удален." ;
        }
    },
}
</script>

<!--bg-red-200 text-red-900-->

<style scoped>

</style>
