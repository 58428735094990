<template>
    <div :class="{'loading': loading}">

        <flash v-if="flash" :flashType="flashType"></flash>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li v-for="(value,key,index) in lang" :key="key" class="nav-item">
                <a class="nav-link" :class="{'active':index==0}" :id="key+'-tab'" data-toggle="tab" :href="'#'+key"
                   role="tab" :aria-controls="key" @click="showPane(index)"
                   :aria-selected="{'true':index==0}">{{ value }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div
                v-for="(value, key, index) in lang"
                :key="key"
                class="tab-pane fade"
                :class="{
                    'active':index==0,
                    'show':index==0
                }"
                v-if="showPaneindex == index"
                :id="key"
                role="tabpanel"
                :aria-labelledby="key+'-tab'
            ">
                <div class="card shadow">
                    <div class="card-body tree_view">
                        <ul class="l_group">
                            <sub-menu
                                :url="url"
                                @select="onSelectMenuItem($event)"
                                v-for="(item,index) in submenu"
                                v-model="submenu[index]"
                                :lang="key"
                                :submenu="basemenu"
                                :key="index"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
        <add-menu-children @add="loadMenu" :url="url" v-model="selectedMenuItem"/>
        <edit-menu-modal @edit="loadMenu" :url="url" :selectedMenuItem="selectedMenuItem" v-model="selectedMenuItem"/>
        <delete-menu-modal @delete="loadMenu" :url="url" v-model="selectedMenuItem"/>
    </div>
</template>

<script>
import SubMenu from "./submenu.vue";
import AddMenuChildren from "./addMenuChildren.vue";
import EditMenuModal from "./editMenuModal.vue";
import DeleteMenuModal from "./deleteMenuModal.vue";
import Flash from "./flash.vue";

export default {
    props: {
        url: String,
        userMenuId: String,
    },

    data: function () {
        return {
            basemenu: [],
            lang: [],
            submenu: [],
            loading: true,
            selectedMenuItem: {},
            flash: false,
            flashType: '',
            showPaneindex:0
        }
    },
    mounted() {
        this.loadMenu();
        this.loadLang();
    },


    methods: {
        onSelectMenuItem(event) {
            this.selectedMenuItem = event;
        },
        loadMenu: function () {
            axios.get(this.url+'/admin/api/menu',{
                params: {
                    userMenuId : this.userMenuId,
                }
            }).then((response) => {
                this.basemenu = response.data;
                if (this.userMenuId == 0 || this.userMenuId == null){
                    this.submenu = this.basemenu.filter(x => x.parent_id == 0);
                }
                else{
                    this.submenu = this.basemenu.filter(x => x.id == this.userMenuId);
                }
                this.loading = false;
            })
                .catch(function (error) {
                    console.log(error);
                });
            this.flash = false;
        },
        loadLang: function () {
            axios.get(this.url+'/admin/api/lang')
                .then((response) => {
                    this.lang = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showPane(index){
            this.showPaneindex = index;
        },
        flashDanger(status){
            this.flashType = status;
            this.flash = !this.flash;
        }
    },
    components: {
        SubMenu,
        AddMenuChildren,
        EditMenuModal,
        DeleteMenuModal,
        Flash,
    }

}
</script>

<style scoped>

</style>
