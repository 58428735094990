<template>
    <!-- Logout Modal-->
    <div class="modal fade" id="addMenuChildrenModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Добавление нового раздела</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Название раздела </label>
                            <input v-model="title" type="text" id="exampleInputEmail1" class="form-control" placeholder="Введите название раздела">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="title=''" type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    <button @click="addMenuChildren" type="button" data-dismiss="modal" class="btn btn-primary">Сохранить изменения</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: Object,
        url: String,
    },
    name: "addMenuChildren",
    data:()=>{
        return({
            title:"",
            visibly: ""
        })
    },
    methods:{
        addMenuChildren(){
            axios.post(this.url+'/admin/api/addMenuChildren',{
                title: this.title,
                id: this.value.id,
                lang: this.value.lang,
            }).then((response)=> {
                this.$emit('add');
                this.title="";
            }).catch( function (error){
                console.log(error);
            });
        }
    },


}
</script>

<style scoped>

</style>
