<template>
    <div class="form-group col-6">
        <label for="menu_id">Раздел:</label>
        <input hidden class="form-control" name="menu_id" id="menu_id" type="text" v-model="selectedSection">
        <v-select
            class="bg-white"
            :options="sections"
            :reduce="sections => sections.menu_id"
            label="title"
            v-model="selectedSection"
        />
    </div>

</template>

<script>

export default {
    name: "selectSection",
    props: {
        url: String,
        currentLang: String,
        defaultSection: {
            type: String,
            default: "0"
        }
    },
    data: () => ({
        sections:[],
        selectedSection: 0
    }),
    methods:{
          async loadSection(){
              axios.get(this.url+'/admin/api/selectSection',{
                  params: {

                  }
              }).then((response) => {
                  response.data.forEach(element => {
                      this.sections.push(element.translates.find(x=>x.locale == this.currentLang) ?? element.translates[0]);
                  })
              }).catch(function (error) {
                  console.log(error);
              });
          },

          onSelectSection(value){
              this.selectedSection = value;
          }
    },
    async mounted() {
        await this.loadSection();
        this.selectedSection = this.defaultSection == "0" ? 0 : Number(this.defaultSection);
    },
    watch:{
        selectedSection(val){
            if (val == null) this.selectedSection = 0;
        }
    }
}
</script>

<style scoped>

</style>
